import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { ColumnSection, Column } from '../../components/column-section';
import { TileSection, Tile } from '../../components/tile-section';

import { graphql } from 'gatsby';

import Img from 'gatsby-image';
import ContactCTA from '../../components/contact-cta';
import { Languages } from '../../tools/languages';

const AboutUsCategoryTiles = ({ categories, placeholderImage }) => {
  return (
    <TileSection>
      {categories.map((category) => {
        const { id, title, slug, image } = category.node;
        return (
          <Tile
            key={id}
            img={image ? image.childImageSharp.fluid : placeholderImage}
            to={slug}
            name={title}
          ></Tile>
        );
      })}
    </TileSection>
  );
};

const EnglishAboutPage = ({ location, data, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={data.about.SEO.title}
        description={data.about.SEO.description}
      />
      <Navigation />
      <main>
        <h1>{data.about.SEO.title}</h1>
        <Img className="image" fluid={data.about.cover.childImageSharp.fluid} />
        <ColumnSection>
          <Column>
            <h3>
              Bavak Security Group is the manufacturer and global supplier of electronic, cyber- and
              physical security solutions for critical infrastructure.
            </h3>
          </Column>
          <Column>
            <p>
              Innovation is an essential part of Bavak. Our team continuously works on designing new
              security solutions. This ranges from the development of the unique integrated security
              products for critical infrastructure, to the best production techniques for a new
              Speedgate. Bavak develops, delivers and maintains security for critical infrastructure
              worldwide.
            </p>
            <p>
              At Bavak, the innovation of cyber-electronic and physical security comes first. Bavak
              also takes direct care of the correct implementation of these security solutions. In
              addition, the highly specialized user training courses and the corrective but also
              preventive maintenance of the equipment over the long term. For more than 50 years
              Bavak has been providing security for a wide variety of customers and this is made
              possible by working with highly qualified personnel, the best service for all security
              equipment and absolute discretion.
            </p>
          </Column>
        </ColumnSection>
        {/* <ColumnSection>
          <Column>
            <h3>Bavak Security Group – the combined strength of quality and knowledge.</h3>
          </Column>
          <Column>
            <p>
              The needs from the customers are the focus for Bavak. This means that we see it as a
              challenge to make security risks manageable for our clients. At Bavak, every project
              is unique and the unburdening of the customer comes first.
            </p>
            <p>
              Therefore the misson of Bavak is: To make the security risks of our customers
              manageable. Together with our customers we analyse what needs to be protected. We
              advice, develop, realize and maintain state-of-the-art security solutions. At Bavak
              the customer comes first.
            </p>
            <p>
              In line with this, the vision of Bavak is: To realize total security solutions by
              integrating physical, electronic and cyber security solutions.
            </p>
            <p>Bavak delivers the combined strength of quality and knowledge.</p>
          </Column>
        </ColumnSection> */}
        {data.categories.edges.length > 0 && (
          <AboutUsCategoryTiles
            categories={data.categories.edges}
            placeholderImage={data.file.childImageSharp.fluid}
          />
        )}
        <ContactCTA language={language} to="/contact/" />
      </main>
    </>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "drone-near-office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    about: strapiAboutpageEn {
      SEO {
        title
        description
      }
      cover {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    categories: allStrapiAboutCategories {
      edges {
        node {
          id
          slug
          title: title_en
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default EnglishAboutPage;
