import React from 'react';
import PropTypes from 'prop-types';

import PlaceholderImage from '../../../static/images/placeholder.jpg';

import { Column, ColumnSection } from '../column-section';
import { SecondaryButton } from '../buttons';
import { Languages } from '../../tools/languages';

import './index.scss';

const ATillZ = ({ language }) => {
  if (language === Languages.NL) {
    return (
      <ColumnSection centerVertically>
        <Column>
          <img id="image" src={PlaceholderImage} alt="" />
        </Column>
        <Column>
          <h3 id="title">A tot en met Z ontzorgen bij Bavak Security Group</h3>
          <p id="content">
            Bavak Security Group is dé toonaangevende system integrator en wereldwijde leverancier
            van elektronische-, cyber-, en fysieke beveiligingsoplossingen voor vitale
            infrastructuur. Samen met onze klanten creëren onze beveiligingsexperts de meest
            geschikte beveiligingsoplossingen voor de uitdagingen van vandaag. Bavak Security Group
            is een Nederlands technologiebedrijf met meer dan 50 jaar ervaring.
          </p>
          <SecondaryButton to="/about/">Hoe wij het doen</SecondaryButton>
        </Column>
      </ColumnSection>
    );
  }

  return (
    <ColumnSection centerVertically>
      <Column>
        <img id="image" src={PlaceholderImage} alt="" />
      </Column>
      <Column>
        <h3 id="title">Peace of mind</h3>
        <p id="content">
          Bavak Security Group is thé leading manufacturer and global provider of electronic, cyber
          and physical security solutions for critical infrastructure. Together with our customers,
          our security experts create the most appropriate security solutions for today's
          challenges. Bavak Security Group is a Dutch technology company with more than 50 years of
          experience.
        </p>
        <SecondaryButton to="/about/">How we do it</SecondaryButton>
      </Column>
    </ColumnSection>
  );
};

ATillZ.propTypes = {
  language: PropTypes.string,
};

ATillZ.defaultProps = {
  language: Languages.EN,
};

export default ATillZ;
