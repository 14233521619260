import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { ColumnSection, Column } from '../../components/column-section';
import { TileSection, Tile } from '../../components/tile-section';

import { graphql } from 'gatsby';

import Img from 'gatsby-image';
import ContactCTA from '../../components/contact-cta';
import { Languages } from '../../tools/languages';

const AboutUsCategoryTiles = ({ categories, placeholderImage }) => {
  return (
    <TileSection>
      {categories.map((category) => {
        const { id, title, slug, image } = category.node;
        return (
          <Tile
            key={id}
            img={image ? image.childImageSharp.fluid : placeholderImage}
            to={slug}
            name={title}
          ></Tile>
        );
      })}
    </TileSection>
  );
};

const DutchAboutPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={data.about.SEO.title}
        description={data.about.SEO.description}
        lang={language}
      />
      <Navigation />
      <main>
        <h1>{data.about.SEO.title}</h1>
        <Img className="image" fluid={data.about.cover.childImageSharp.fluid} />
        <ColumnSection>
          <Column>
            <h3>
              Bavak Security Group is de fabrikant en wereldwijde leverancier van elektronische-,
              cyber-, en fysieke beveiliging oplossingen voor vitale infrastructuur.
            </h3>
          </Column>
          <Column>
            <p>
              Innovatie is een essentieel onderdeel van Bavak. Ons team werkt continue aan het
              ontwerpen van nieuwe beveiliging oplossingen. Dit gaat van de ontwikkeling van de
              unieke geïntegreerde beveiligingsproducten voor vitale infrastructuur, tot de beste
              productie technieken voor een nieuwe speedgate. Bavak levert en onderhoudt wereldwijd
              de beveiliging van vitale infrastructuur.
            </p>
            <p>
              Bij Bavak staat de innovatie van cyber- elektronische en fysieke beveiliging op de
              eerste plaats. Bavak draagt vervolgens ook de directe zorg voor de juiste
              implementatie van deze beveiligingsoplossingen. Daarbij komen de zeer specialistische
              gebruikers trainingen en het correctieve maar ook preventieve onderhoud van de
              apparatuur over de langere termijn. Al meer dan 50 jaar levert Bavak de beveiliging
              voor de meest uiteenlopende klanten en dit wordt mogelijk gemaakt doordat er wordt
              gewerkt met zeer gekwalificeerd personeel, service voor alle beveiliging apparatuur en
              absolute discretie.
            </p>
          </Column>
        </ColumnSection>
        {/* <ColumnSection>
          <Column>
            <h3>Bavak Security Group – de gebundelde kracht van kwaliteit en kennis.</h3>
          </Column>
          <Column>
            <p>
              De absolute focus van Bavak ligt bij onze klanten. Dat betekent dat wij het als een
              uitdaging zien om de beveiligingsrisico’s voor onze klanten beheersbaar te maken. Bij
              Bavak is elk project uniek en staat het ontzorgen van de klant op de eerste plaats.
            </p>
            <p>
              De missie van Bavak is dan ook: Het beheersbaar maken van de veiligheidsrisico’s van
              onze klanten. Samen met de klant analyseren wij wat er beveiligd moet worden. Wij
              adviseren, ontwikkelen, realiseren en onderhouden state-of-the-art beveiliging
              oplossingen. Bij Bavak staat de klant centraal.
            </p>
            <p>
              Daarop aansluitend is de visie van Bavak: Bavak realiseert een totale beveiliging
              oplossing door het integreren van elektronische, cyber- en fysieke
              beveiligingsmaatregelen.
            </p>
            <p>Bavak levert de gebundelde kracht van kwaliteit en kennis.</p>
          </Column>
        </ColumnSection> */}
        {data.categories.edges.length > 0 && (
          <AboutUsCategoryTiles
            categories={data.categories.edges}
            placeholderImage={data.file.childImageSharp.fluid}
          />
        )}
        <ContactCTA language={language} to="/contact/" />
      </main>
    </>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "drone-near-office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    about: strapiAboutpageNl {
      SEO {
        title
        description
      }
      cover {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    categories: allStrapiAboutCategories {
      edges {
        node {
          id
          slug
          title: title_nl
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default DutchAboutPage;
