import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { SecondaryButton } from '../buttons';
import { ColumnSection, Column } from '../column-section';
import { Languages } from '../../tools/languages';

import Img from 'gatsby-image';

import './index.scss';

const query = graphql`
  {
    file(relativePath: { eq: "drone-near-office.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const ContactCTA = ({ language, title, description, to }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) =>
        language === Languages.EN ? (
          <ColumnSection dark className="contact-cta">
            <Column>
              <div className="content">
                <h3>{title ?? 'Contact us for more information'}</h3>
                <p>
                  {description ??
                    'Good security requires expertise. Bavak has been providing the best security worldwide for more than 50 years. If you also want to know how, please contact us.'}
                </p>
              </div>
              <div>
                <SecondaryButton to={to}>Contact</SecondaryButton>
              </div>
            </Column>
            <Column>
              <Img
                className="image"
                fluid={data.file.childImageSharp.fluid}
                alt="Drone flying near the Bavak Office."
              />
            </Column>
          </ColumnSection>
        ) : (
          <ColumnSection dark className="contact-cta">
            <Column>
              <div className="content">
                <h3>{title ?? 'Neem contact op voor meer informatie'}</h3>
                <p>
                  {description ??
                    'Goede beveiliging vraagt om expertise. Bavak levert al meer dan 50 jaar wereldwijd de beste beveiliging. Wil u ook weten hoe, neem dan contact op.'}
                </p>
              </div>
              <div>
                <SecondaryButton to={to}>Contact</SecondaryButton>
              </div>
            </Column>
            <Column>
              <Img
                className="image"
                fluid={data.file.childImageSharp.fluid}
                alt="Drone flying near the Bavak Office."
              />
            </Column>
          </ColumnSection>
        )
      }
    />
  );
};

ContactCTA.propTypes = {
  to: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

ContactCTA.defaultProps = {
  to: '/contact/',
  language: Languages.EN,
};

export default ContactCTA;
