import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { ColumnSection, Column } from '../../components/column-section';
import { TileSection, Tile } from '../../components/tile-section';

import { graphql } from 'gatsby';

import Img from 'gatsby-image';
import { Languages } from '../../tools/languages';
import { SecondaryButton } from '../../components/buttons';

const Vacancies = ({ vacancies }) => {
  return (
    <TileSection>
      {vacancies.edges.map((vacancy) => {
        const { id, slug, title, image } = vacancy.node;
        return <Tile key={id} to={slug} img={image.childImageSharp.fluid} name={title}></Tile>;
      })}
    </TileSection>
  );
};

const EnglishCareersPage = ({ location, data, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="Careers" lang={language} />
      <Navigation />
      <main>
        <h1>Working at Bavak</h1>
        <Img
          className="image"
          fluid={data.file.childImageSharp.fluid}
          alt="Rapiscan machine provided by Bavak Security Group"
        />
        <ColumnSection>
          <Column>
            <h3>Come and work with us</h3>
          </Column>
          <Column>
            <p>
              Innovation is an essential part of Bavak. Our team continuously works on designing new
              security solutions. This ranges from the development of the unique integrated security
              products for critical infrastructure, to the best production techniques for a new
              Speedgate. Bavak develops, delivers and maintains security for critical infrastructure
              worldwide.
            </p>
            <p>
              At Bavak, the innovation of cyber-electronic and physical security comes first. Bavak
              also takes direct care of the correct implementation of these security solutions. In
              addition, the highly specialized user training courses and the corrective but also
              preventive maintenance of the equipment over the long term. For more than 50 years
              Bavak has been providing security for a wide variety of customers and this is made
              possible by working with highly qualified personnel, the best service for all security
              equipment and absolute discretion.
            </p>
          </Column>
        </ColumnSection>
        <Vacancies vacancies={data.vacancies} />
        <ColumnSection dark>
          <Column>
            <div>
              <h3>Do you have questions about our vacancies?</h3>
              <p>
                Are you curious about how our process works or do you want more information? Get in
                touch with us!
              </p>
            </div>
            <div>
              <SecondaryButton to="/contact/">Contact us</SecondaryButton>
            </div>
          </Column>
        </ColumnSection>
      </main>
    </>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vacancies: allStrapiVacancies {
      edges {
        node {
          id
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title: title_en
        }
      }
    }
  }
`;

export default EnglishCareersPage;
